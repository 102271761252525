import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare let SockJS: new (arg0: string) => any;
declare let Stomp: { over: (arg0: any) => any; };
// declare let SockJS;
// declare let Stomp;
@Injectable({
  providedIn: 'root'
})
export class CoversationService {
  private stompClient: any;
  user: any = JSON.parse(localStorage.getItem('userdetails') || '{}');
  conversations: BehaviorSubject<any> = new BehaviorSubject<any>({});
  chatSocketUrl: any = environment.chatSocketUrl;
  constructor() { }
  initializeWebSocketConnection(streamId?:any) {
    // const serverUrl = environment.chatURL;
    const ws = new SockJS(this.chatSocketUrl+'/sock');
    this.stompClient = Stomp.over(ws);
    const that = this;

    this.stompClient.connect({}, function (frame:any) {
      console.log(frame,'frame');
      
      if (frame?.command === 'CONNECTED') {
        console.log('connected');
        
        // that.hasJoinedRoom$$.next(true);
      }

      // that.stompClient.subscribe('/chat-room/' + streamId, (message:any) => {
      //   if (message.body) {
      //     let msg = JSON.parse(message.body);
      //     // that.handReasebehav.next(msg);
      //     // if (msg.type === 'CHAT' || msg.type === 'RAISE_HAND') {
      //     //   that.chatMessage$$.next([
      //     //     ...that.chatMessage$$.value,
      //     //     {
      //     //       content: msg.content,
      //     //       image: msg.image,
      //     //       time: msg.time,
      //     //       type: msg.type,
      //     //       sender: msg.sender,
      //     //       userId: msg.userId,
      //     //     },
      //     //   ]);
      //     // } else if (msg.type === 'SESSION_LEAVE' && +msg.userId === +that.id) {
      //     //   const route = that._AuthService.route$$.value;
      //     //   if (that.streamId)
      //     //     localStorage.setItem('recentLeftSession', that.streamId);
      //     //   if (route) window.location.href = route;
      //     //   else
      //     //     window.location.href = `learner/dashboard/sessions?courseId=${that.courseId}`;
      //     // }

      //     // that.msg.push(JSON.parse(message.body));
      //     // that.chatOpenClosed.next(true);
      //     // that._EventService.publish(
      //     //   'socket-message',
      //     //   JSON.parse(message.body)
      //     // );
      //   }
      // });
    });
  }
  // initializeWebSocketConnection(): Observable<any> {
  //   console.log('initialize web socket connection function called');
    
  //   const socket = new SockJS('https://diverseu-lms-backend.staging.fundflu.com/sock');
  //   this.stompClient = Stomp.over(socket);
  //   return new Observable((observer) => {
  //     this.stompClient.connect({}, (frame:any) => {
  //       console.log(frame,'frame');
        
  //       console.log('connected');
        
  //       observer.next(frame);
  //     });
  //   });
  // }

  enterRoom(newRoomId: any, user: any) {
    var roomId = newRoomId;
    var topic = `/chat-app/chat/${newRoomId}`;
    this.stompClient.subscribe(`/chat-room/${roomId}`, (message: any) => {
      console.log(message, 'message');
      this.conversations.next(JSON.parse(message.body))
    });
    var username = user;
    this.stompClient.send(`${topic}/joinRoom`,
      {},
      JSON.stringify({ sender: username, type: 'JOIN' })
    );
  }

  sendMessage(message: any, roomId: any) {
    var topic = `/chat-app/chat/sendMessage`;
    // var chatMessage = {
    //   chatRoomId: event.currentChatRoomId,
    //   content: event.msgText,
    // };
    var chatMessage = {
      "chatRoomId": roomId, 
      "content": message,
      "senderUserId": this.user.userId};
    this.stompClient.send(topic, {}, JSON.stringify(chatMessage));
  }

  subscribeToChannel(channel: string): Observable<any> {
    return new Observable((observer) => {
      this.stompClient.subscribe(channel, (message:any) => {
        observer.next(message);
      });
    });
  }
  covertLocalTime(dateTime:any){
    const utcTimeString = dateTime?.replace(' ','T')+':00Z';
    const utcDate = new Date(utcTimeString);
    const localTimeString = utcDate?.toLocaleString();
    return localTimeString
  }

  destroyConnection(){
    this.stompClient.disconnect();
  }
}
