import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router, private session: SessionService,private toastr: ToastrService) { }

  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   return next.handle(request);
  // }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let authservice =this.injector.get(SessionService)  
    let usersdetail = this.session.getSession();
    if (!usersdetail?.authToken) {
      return next.handle(req)
    }
    else {
      let tokenizeReq = req.clone({
        setHeaders: {
          authorization: ` ${usersdetail.authToken}`
        }
      });
      return next.handle(tokenizeReq).pipe(
        tap((event: any) => {
          if (event instanceof HttpResponse) { }
        },
          error => { 
this.toastr.error(error.error.message)            
           }));
    }
  }
}
