import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }
  getSession() {
    return JSON.parse(localStorage.getItem('userdetails') || '{}')
  }
}
