import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  baseUrl: string = environment.baseUrl;
  updateImg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  getRequest<T>(requestUrl:string){
    return this.http.get(`${this.baseUrl}/api/v1/${requestUrl}`).toPromise()
    .then((res) => res as T)
    .catch((err) => err as T)
  }
  getReq<T>(requestUrl:string){
    return this.http.get(`${this.baseUrl}/api/v1/${requestUrl}`);
  }
  postReq<T>(requestUrl:string,body:any){
    return this.http.post(`${this.baseUrl}/api/v1/${requestUrl}`,body);
  }
  putReq<T>(requestUrl:string,body:any){
    return this.http.put(`${this.baseUrl}/api/v1/${requestUrl}`,body);
  }
  postRequest<T>(requestUrl:string,data:any){
    return this.http.post(`${this.baseUrl}/api/v1/${requestUrl}`,data).toPromise()
    .then((res) => res as T)
    .catch((err) =>err as T)
  }
  putRequest<T>(requestUrl:string,data:any){
    return this.http.put(`${this.baseUrl}/api/v1/${requestUrl}`,data).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err?.error?.message);})
  }
  deleteRequest<T>(requestUrl:string){
    return this.http.delete(`${this.baseUrl}/api/v1/${requestUrl}`).toPromise()
    .then((res) => res as T)
    .catch((err) => {this.toastr.error(err?.error?.message);})
  }
  
  updateProfileImg(value: boolean) {
    this.updateImg.next(value);
  }
}
