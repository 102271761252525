import { Component, OnInit } from '@angular/core';
import { requestUrl } from './request-url.const';
import { CrudService } from './services/crud.service';
import { NotificationService } from './services/notification.service';
import { CoversationService } from './services/coversation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DiverseU_user';

  constructor(private crud: CrudService, private notificationService: NotificationService,
    private conversation: CoversationService) {
    this.conversation.initializeWebSocketConnection();
  }

  ngOnInit(): void {
    // this.getNotification()
  }

  // getNotification() {
  //   this.crud.getReq(requestUrl.getNotifications).subscribe((res: any) => {
  //     if (res) {
  //       this.notificationService.saveNotifications(res)
  //     }
  //   })
  // }

}
