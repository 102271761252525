import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { requestUrl } from '../request-url.const';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notification = new BehaviorSubject({})
  notifyEvent = this.notification.asObservable()
  constructor(private crud:CrudService) { }

  saveNotifications(data:any) {
    this.notification.next(data);
  }

}
